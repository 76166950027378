import { buildLocaleMessages } from 'common';
import German from '../content/locales/de.json';
import English from '../content/locales/en.json';
import GermanAT from '../content/locales/de-AT.json';
import GermanCH from '../content/locales/de-CH.json';
import FrenchCH from '../content/locales/fr-CH.json';

export const getStrings = (locale: Locale) => {
	return buildLocaleMessages(locale, {
		'en-GB': English,
		'de-DE': German,
		'de-CH': GermanCH,
		'de-AT': GermanAT,
		'fr-CH': FrenchCH
	});
};
