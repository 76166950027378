import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

import { useStyles } from './TenantHeader.desktop.styles';
import LocaleSwitch from '../LocaleSwitch';
import HeaderUserNavBar from '../../HeaderUserNavBar/HeaderUserNavBar';
import { HeaderUserNavBarProps } from '../../HeaderUserNavBar/HeaderUserNavBar.types';
import { USER_URLS } from '../../../urls';
import setStateInCookies from '../../../cookies/setStateInCookies';
import { TenantConfig } from '../../../../../catalog/src/utils/tenant';
import TenantBreadcrumbs from './TenantBreadcrumbs';
import { useRouter } from 'next/router';

type Props = {
	tenantConfig: TenantConfig;
	userNavBarProps: HeaderUserNavBarProps;
};

export const TenantHeaderDesktop = (props: Props) => {
	const { tenantConfig, userNavBarProps } = props;
	const classes = useStyles();
	const router = useRouter();
	const { companyPage } = tenantConfig;
	const tenant = companyPage?.slug || '';
	const { locale } = useIntl();

	const handleRegisterClick = () => {
		const url = new URL(window.location.href);
		url.searchParams.delete('state');
		const nonce = setStateInCookies({});
		router.push(USER_URLS.registration({ nonce, locale, tenant }));
	};

	const handleLoginClick = () => {
		const url = new URL(window.location.href);
		url.searchParams.delete('state');
		const nonce = setStateInCookies({});
		router.push(USER_URLS.loginWithQuery({ nonce, locale, tenant }));
	};

	return (
		<Box className={classes.wrapper}>
			<Box width="100%">
				<Box className={classes.firstRow}>
					<TenantBreadcrumbs />
					<Box>
						<HeaderUserNavBar
							{...userNavBarProps}
							loginActionLocaleId="common.login.tenant-title"
							loginHrefOrHandler={handleLoginClick}
							registrationHrefOrHandler={handleRegisterClick}
						/>
					</Box>
				</Box>
				<Box className={classes.localesContainer}>
					<LocaleSwitch />
				</Box>
			</Box>
		</Box>
	);
};
