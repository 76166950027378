import { booleanFilter, ContentTransformers, formatters, initializeApollo, Types } from 'common';
import { GetCompanyConfiguration } from './queries';
import { GetCompanyConfigurationQuery } from './queries.generated';
import getContentById from '../queries/getContentById';
import { ParsedUrlQuery } from 'querystring';
import { getStrings } from '../i18n';

/**
 * List of tenant ids that have a custom version of the platform.
 */
export const companyTenantsIds: Array<Types.UUID> = JSON.parse(
	process.env.NEXT_PUBLIC_TENANT_IDS || '["a3ea5c07-3cfa-4687-b552-4970934fe5d9"]'
);
// eslint-disable-next-line no-console
console.log('process.env.NEXT_PUBLIC_TENANT_IDS', process.env.NEXT_PUBLIC_TENANT_IDS);
// eslint-disable-next-line no-console
console.log('companyTenantsIds', companyTenantsIds);

export type TenantConfig = GetCompanyConfigurationQuery['company'] & {
	contentTitle?: string;
};

export const getTenantsConfiguration = async (): Promise<Array<TenantConfig>> => {
	try {
		if (!companyTenantsIds || companyTenantsIds.length === 0) {
			console.warn('No tenant IDs configured');
			return [];
		}

		const apolloClient = initializeApollo();
		const configurations = await Promise.all(
			companyTenantsIds
				.map(async (tenantId) => {
					try {
						const { data } = await apolloClient.query({
							query: GetCompanyConfiguration,
							variables: {
								companyId: tenantId
							}
						});
						return data?.company;
					} catch (error) {
						console.error(`Error fetching config for tenant ${tenantId}:`, error);
						return null;
					}
				})
				.filter(booleanFilter)
		);

		return configurations;
	} catch (error) {
		console.error('Error while fetching tenants configuration:', error);
		return [];
	}
};

export const getTenantConfigFromPathname = async (
	tenantsConfig: Array<TenantConfig>,
	pathname: string,
	query: ParsedUrlQuery,
	messages: Record<string, string>
): Promise<TenantConfig | null> => {
	if (!tenantsConfig || tenantsConfig.length === 0) {
		return null;
	}

	// eslint-disable-next-line no-console
	console.log('**** getTenantConfigFromPathname', {
		tenantsConfig,
		pathname,
		query
	});
	if (
		!pathname.includes('/mediathek/') &&
		pathname.match(/\/content\/[^/]+/) &&
		typeof query?.id === 'string' &&
		query.id != null
	) {
		try {
			const { content } = await getContentById(query.id);
			const currentTenantConfig = tenantsConfig.find(
				(config) => content?.provider?.id === config.id
			);
			if (currentTenantConfig && content) {
				return {
					...currentTenantConfig,
					contentTitle: `${content?.title || ''} - ${formatters.formatEnum(
						content.brand,
						{
							options: ContentTransformers.brand,
							messages
						}
					)}`
				};
			}
		} catch (error) {
			console.error('Error fetching content:', error);
		}
		return null;
	} else if (pathname.includes('/partner-fortbildungsforum/')) {
		return (
			tenantsConfig.find((config) => pathname.includes(config.companyPage?.slug || '')) ||
			null
		);
	} else if (
		[
			'/user/login',
			'/user/registration',
			'/user/complete-profile',
			'/user/forgot-password',
			'/user/reset-password'
		].some((path) => pathname.includes(path))
	) {
		const tenant = query.tenant as string;
		if (!tenant) {
			return null;
		}
		return tenantsConfig.find((config) => tenant === config.companyPage?.slug) || null;
	}
	return null;
};
