import React, { useContext, useEffect, useState } from 'react';
import {
	getTenantConfigFromPathname,
	getTenantsConfiguration,
	TenantConfig
} from '../../../catalog/src/utils/tenant';
import { useRouter } from 'next/router';
import { getStrings } from '../../../catalog/src/utils/i18n';

export type TenantContextType = TenantConfig | null;

const defaultTenantContext = null;

const TenantContext = React.createContext<TenantContextType>(defaultTenantContext);
const useTenantContext = () => useContext(TenantContext);

type TenantContextProviderProps = {
	children: React.ReactNode;
	tenantConfig: TenantConfig | null;
};

const TenantContextProvider = (props: TenantContextProviderProps) => {
	const [tenantConfig, setTenantConfig] = useState(props.tenantConfig);
	const router = useRouter();
	const messages = getStrings(router.locale as Locale);
	const {
		pathname,
		query: { tenant, id }
	} = router;

	useEffect(() => {
		const fetchTenantConfig = async () => {
			const tenantsConfig = await getTenantsConfiguration();
			const newTenantConfig = await getTenantConfigFromPathname(
				tenantsConfig,
				pathname,
				{ tenant, id },
				messages
			);
			if (newTenantConfig) {
				setTenantConfig(newTenantConfig);
			}
		};
		// eslint-disable-next-line no-console
		console.log('**** fetching tenant config');
		if (!tenantConfig) {
			fetchTenantConfig();
		}
	}, [pathname, tenant, id]);
	return <TenantContext.Provider value={tenantConfig}>{props.children}</TenantContext.Provider>;
};
export { TenantContextProvider, useTenantContext };
